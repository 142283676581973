<template>
  <div>
    <CRow class="clapans">
      <CCol md="12">
        <CCard>
          <CCardHeader>
            <div class="d-flex aligin-items-center justify-content-between">
              <span>Настройки</span>
              <div class="col-md-6  d-flex justify-content-end align-items-center">

                <CButton color="danger" @click="deleteAll()">
                  Удалить всех клиентов
                </CButton>
                <CButton color="success" @click="openModal()">
                  Добавить
                </CButton>
              </div>

            </div>
          </CCardHeader
          >
          <CCardBody>
            <CDataTable
                class="mb-0 table-outline"
                hover
                :items="tableItems"
                :fields="tableFields"
                head-color="light"
                column-filter
                table-filter
                sorter
                :tableFilter="{'label':'Поиск','placeholder':'Введите слово для поиска'}"

            >
              <td slot="action" slot-scope="{ item,index }">
                <CButton color="success" @click="deleteItem(item,index)">
                  Удалить
                </CButton>
                <CButton color="success" @click="updateItem(item,index)">
                  Изменить
                </CButton>
              </td>
            </CDataTable>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CModal title="Настройки" :show.sync="warningModal">
      <CCard>
        <CCardBody>
          <CForm v-on:submit="submitForm()">
            <CInput type="hidden" v-model="variable.id" horizontal/>
            <CInput type="text" v-model="variable.name" label="Название" horizontal/>
            <CInput type="text" v-model="variable.type" label="Тип" horizontal/>
            <CInput type="text" v-model="variable.value" label="Значение" horizontal
            />
            <CButton color="success" type="submit">
              Сохранить
            </CButton>
          </CForm>
        </CCardBody>
      </CCard>
    </CModal>
    <div class="alert_block">
      <CAlert color="warning" closeButton v-if="warning">
        {{ alert_message }}
      </CAlert>
      <CAlert color="primary" closeButton v-if="success">
        {{ alert_message }}
      </CAlert>
    </div>
  </div>
</template>

<script>
import MainChartExample from "./charts/MainChartExample";
import WidgetsDropdown from "./widgets/WidgetsDropdown";
import WidgetsBrand from "./widgets/WidgetsBrand";

const qs = require('qs')
const axios = require('axios')
export default {
  name: "Dashboard",
  components: {
    MainChartExample,
    WidgetsDropdown,
    WidgetsBrand,
  },
  data() {
    return {
      all_select: false,
      warning: false,
      success: false,
      alert_message: '',
      warningModal: false,
      isSelect: false,
      action_switcher: '',
      action_switcher_city: '',
      id: "",
      variable: {
        id: 0,
      },
      tableItems: [],
      tableFields: [
        {key: "id", label: "ID", filter: false},
        {key: "name", label: "Название"},
        {key: "value", label: "Значение", _classes: "text-center"},
        {key: "type", label: "Тип", _classes: "text-center"},
        {key: "action", label: "Действия", sorter: false, filter: false},
      ],
    };
  },
  mounted() {
    this.getResults(0);
  },
  methods: {
    openModal() {
      this.warningModal = !this.warningModal;
      this.variable.id = 0;
      this.variable.value = '';
      this.variable.name = '';
    },
    deleteAll() {
      if (confirm("Вы действительно хотите удалить все данные из базы?")) {
        let app = this;
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + window.auth.token;
        axios.get('/auth/traffics/delete-all').then(response => {
          app.success = true;
          app.alert_message = 'Все клиенты успешно удалены';
          setTimeout(function () {
            app.success = false;
          }, 3000)
        }).catch(error => {
          if (error.response.status == 401) {
            window.auth.logout();
          }
        });
      }
    },
    submitForm() {
      event.preventDefault();
      let app = this;
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + window.auth.token;
      axios(
          {
            method: 'post',
            url: '/auth/variables/add',
            data: qs.stringify(this.variable),
          })
          .then(({data}) => {
            app.getResults();
            app.warningModal = false;
            app.success = true;
            app.alert_message = 'Успешно сохранено';
            app.setting = {};
            setTimeout(function () {
              app.success = false;
            }, 3000)
          }).catch(function (error) {
        app.warning = true;
        app.alert_message = error.response.data.detail;
        setTimeout(function () {
          app.warning = false;
        }, 3000)

      })
    },
    getResults() {
      let app = this;
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + window.auth.token;
      axios.get('/auth/variables/list').then(response => {
        app.tableItems = response.data.variables;
        for (var i = 0; i < app.tableItems.length; i++) {
          app.tableItems[i].action = true;
        }
      }).catch(error => {
        console.log(error);
        if (error.response.status == 401) {
          window.auth.logout();
        }
      });
    },
    deleteItem(item, index) {
      let app = this;
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + window.auth.token;
      axios.get('/auth/variables/delete/' + item.id).then(response => {
        app.tableItems.splice(index, 1);
      }).catch(error => {
        if (error.response.status == 401) {
          window.auth.logout();
        }
      });

    },
    updateItem(item, index) {
      let app = this;
      app.warningModal = true;
      app.variable = item;

    },


  },
};
</script>
<style>
.clapans .form-inline {
  margin-left: 15px;
}

.alert_block {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 100000;
}
</style>
